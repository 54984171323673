<template>
  <section>    

    <b-table
      class="mt-2 table-billing"
      :items="items"
      :fields="fields"
      responsive
      :busy="loading" 
      small
    >
      <template v-slot:cell(data.license)="data">{{
        showLicense(data.item.data.license)
      }}</template>

      <template v-slot:thead-top="">
        <b-tr>
          <b-th>{{ $t('ORGANISATION.TITLE') }}</b-th>
          <b-th class="table-license" colspan="3">{{ $t('LICENSE.TITLE') }}</b-th>
          <b-th class="table-logins" colspan="3">{{ $t('BILLING.AUTHENTICATIONS') }}</b-th>
          <b-th class="table-verifications" colspan="3">{{ $t('BILLING.VERIFICATIONS') }}</b-th>
        </b-tr>
      </template>
        <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner variant="secondary" class="align-middle"></b-spinner>
        </div>
      </template>

    </b-table>
     
  </section>
</template>
<script>
export default {
  props: ["from_date"],
  data() {
    return {
      clearOption: false,
      items: [],     
      loading: false,
      fields: [
        { key: 'organisation_name', label: this.$t('NAME') },
        { key: 'data.license', label: this.$t('LICENSE.LEVEL'), thClass: 'table-license', tdClass: 'table-license' },
        { key: 'data.accounts', label: this.$t('USERS'), thClass: 'table-license', tdClass: 'table-license' },
        { key: 'data.functionbox', label: this.$t('FUNCTIONBOX.TITLE'), thClass: 'table-license', tdClass: 'table-license' },
        { key: 'data.auth.freja', label: this.$t('AUTH_METHODS.freja'), thClass: 'table-logins', tdClass: 'table-logins' },
        { key: 'data.auth.freja_org', label: this.$t('AUTH_METHODS.freja-org'), thClass: 'table-logins', tdClass: 'table-logins' },
        { key: 'data.auth.bankid_se', label: this.$t('AUTH_METHODS.bankid-se'), thClass: 'table-logins', tdClass: 'table-logins' },
        { key: 'data.verify.freja', label: this.$t('AUTH_METHODS.freja'), thClass: 'table-verifications', tdClass: 'table-verifications' },
        { key: 'data.verify.sms', label: this.$t('AUTH_METHODS.sms'), thClass: 'table-verifications', tdClass: 'table-verifications' },
        { key: 'data.verify.bankid_se', label: this.$t('AUTH_METHODS.bankid-se'), thClass: 'table-verifications', tdClass: 'table-verifications' }
      ]
    };
  },
  methods: {  
    changedMonth(){
      this.getLicense();
    },     
    getLicense: function() {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/billing/organisations", {
            month: this.from_date + "-01"
        })
        .then(response => {
          self.items = response.data;
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    showLicense: function(license) {
      return this.$t('LICENSE.LEVELS.' +  license);
    },
    redrawTable() {
      this.getLicense();
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.getLicense();
  }
};
</script>
<style>
</style>